<template>
    <div style="background: #f5f5f5;">
        <qy-top-nav title="退货" />
        <van-tabs v-model="status" @change="refreshList">
            <van-tab title="全部"   name="-1"></van-tab>
            <van-tab title="退货中" name="4"></van-tab>
            <van-tab title="已退货" name="5"></van-tab>
        </van-tabs>
        <div  class="noData"v-if="dataList.length<1">
            当前查询无数据
        </div>
        <div class="goodsList" v-if="dataList.length" :style="{height: containerHeight}">
            <div class="shopGoodsContainer" v-if="dataList.length" v-for="(order,orderIndex) in dataList" @click="openDetail(order.orderId)">
                <template v-if="order.goodsList&&order.goodsList.length>0">
                    <div class="shopTitle">
                        <icon class="van-icon van-icon-send-gift" />{{order.shopName}}
                        <icon class="van-icon van-icon-arrow" />
                        <span style="float:right;color:orange;margin-right: 20px;">{{statusDesc[order.status]}}</span>
                    </div>
                    <ul>
                        <li v-for="(item,goodsIndex) in order.goodsList" :key="goodsIndex">
                            <div class="listDetails">
                                <div class="shopImg">
                                    <img :src="getImgUrl(item.goodsLogo)" alt="">
                                </div>
                                <div class="goodsPresentation">
                                    <div class="goodsTitle">
                                        <p class="p1">{{item.goodsName}}
                                            <span class="goodsPrice">{{item.goodsDealerPrice > 0 ? item.goodsDealerPrice : item.goodsPrice}}</span>
                                        </p>
                                        <p class="p2">{{item.goodsRemark}}
                                            <span class="goodsNum">
                                                <i class="van-icon van-icon-cross" />{{item.goodsNum}}
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                       <!-- -->
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div style="text-align: right;font-size: 13px;font-weight: bold;letter-spacing: 1px;padding-right: 10px;">订单总价
                        <span style="font-size: 12px"> ¥</span>{{qy.formatMoney(order.orderMoney)}}
                    </div>
                    <div style="margin-top: 5px;text-align: right;padding-right: 10px;">
                        <van-button size="mini" plain  type="danger" @click.stop="quitReturn(order.orderId)" v-show="order.status==4">取消退货</van-button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
import request from "@/utils/request";
export default {
    name: "returnList",
    components: {},
    data() {
        return {
            containerHeight: 0,
            status: -1,
            statusDesc: {
                4: "退货中",
                5: "已退货",
            },
            dataList: [],
            total: 0,
            returnFlag:"",
        }
    },
    created() {
        this.refreshList();
    },
    methods: {
        refreshList() {
            let status=this.status>=0?this.status:"";
            if (!status){
                this.returnFlag = "returnFlag";
            }else {
                this.returnFlag = "";
            }
            request.post("/api/order/list", {
                status: status,
                returnFlag:this.returnFlag,
            }).then(rs => {
                this.dataList = rs.data.list;
            })
        },
        quitReturn(orderId){
            this.Dialog.confirm({
                message: '确定要取消退货吗?',
            }).then(()=>{
                request.post("/api/order/quitReturn",{orderId:orderId}).then(rs=>{
                    if(rs.success){
                        this.Toast("取消成功");
                        this.refreshList()
                    }else {
                        this.Toast("操作失败");
                    }
                });
            })
        },
        openDetail(orderId) {
            this.$router.push({ path: "/orderDetail" ,query:{orderId:orderId}})
        },
    },
    mounted() {
        this.containerHeight = document.documentElement.clientHeight - 120 + "px";
    },
}
</script>
<style type="text/css" scoped="">
#payTypePopup ul{
    font-size: 14px;
    margin-bottom: 0.1rem;
}
#payTypePopup>ul>li{
    text-align: center;
    padding: 12px 0px;
    margin: 0px 20px;
    border-bottom: 1px #ddd solid;
    border-radius: 5px;
}
#payTypePopup>ul>li:hover{
    background-color: #feb74c;
    color: #ffffff;
}
.listDetails {
    display: flex;
    padding-left: 30px;
}

.van-checkbox {
    min-width: 24px;
}

.shopTitle {
    height: 50px;
    line-height: 50px;
}

.shopTitle .van-checkbox {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: 4px;
}

.shopImg {
    width: 150px;
    margin: 0 10px;
}

.shopImg img {
    width: 120px;
    height: 120px;
    border-radius: 10px;

}

.shopGoodsContainer {
    margin-top: 10px;
    border-radius: 12px;
    background: #fff;
}

.goodsTitle {
    padding-right: 40px;
    position: relative;
    font-size: 12px;
}

.goodsTitle .p1 {
    font-weight: bold;
}

.goodsTitle .p2 {
    color: #9c9c9c;
    background: #fbfbfb;
    padding: 4px 2px;
}

.goodsPrice {
    position: absolute;
    right: 10px;
}

.goodsNum {
    position: absolute;
    right: 10px;

}

.goodsNum .van-icon {
    position: relative;
    top: 2px;
}

.goodsPrice {

    color: red;
    font-weight: bold;
}

.shopTitle .van-icon {
    position: relative;
    top: 3px;
}

.shopTitle .van-icon-send-gift {
    color: red;
    margin: 0 3px;
    font-size: 25px;
    top: 6px;
}

.goodsList {
    padding-bottom: 20px;
    overflow: auto;
}

.goodsPresentation {

    flex-grow: 1;
}
.noData{
    font-size: 13px;
    margin-bottom: 20px;
    padding: 0.8em;
    text-align: center;
}
</style>